const HEADER_CONSTANT = {
  HEADER_LOGO: 'HEADER LOGO',
  MENU_LINK: 'MENU LINK',
  DEALS_LINK: 'DEALS LINK',
  CATERING_LINK: 'CATERING LINK',
  SIGN_IN_LINK: 'SIGN IN LINK',
  CART_ICON: 'CART ICON',
  LANGUAGE_SELECTOR: 'LANGUAGE SELECTOR',
  SUPPORT_LINK: 'SUPPORT SECTION',
  GUEST_USER_SINGIN_TEXT: 'signInSignUp',
  LOGGEDIN_USER_SIGNIN_TEXT: 'Account',
  CART_ICON_100_PERCENT: 'CART ICON ANIMATION_100%',
  CART_ICON_120_PERCENT: 'CART ICON ANIMATION_120%',
  BACK_TO_CART: 'backToCart',
  USER_SINGIN_TEXT: 'Sign In',
  GO_TO_HOME_PAGE: 'GoToHomePage',
  LETS_GET_COOK: 'letsgetcook',
  CHECKOUT: 'checkout',
  TRACK_ORDER: 'trackOrder',
  NEED_HELP: 'needHelp',
  SUPPORT: 'support',
  CART: 'CART',
  ZERO: 0,
  CART_ALT_TEXT: 'Cart',
  SET_HIDE_DISPOSITION_BAR: 'SET_HIDE_DISPOSITION_BAR',
  SET_HIDE_NAV_BARS: 'SET_HIDE_NAV_BARS',
  LANGUAGE_SELECTOR_BUTTON_NAME: 'language-selector',
  STRING_NO: 'NO',
  GO_BACK: 'goBack',
};
export default HEADER_CONSTANT;
